@media screen and (max-width: 800px) {

    .homePicsContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 32vh;
        row-gap: 5vh;
        padding: 4vh 7vw;
    }
}

.studioPic {
    display: flex;
    justify-content: center;
    width: 100%;
}

.studio {
    max-width: 100%;
    object-fit: cover;
}

.studioDescription {
    padding: 1vh 7vw;
}

.homeAnnuncio {
    margin: 2vh 4vw;
    padding: 2vh 3vw;
    margin-bottom: 5vh;
    border: solid 2px rgba(218, 162, 203, 0.5);
    border-radius: 4px;
}

@media screen and (min-width: 800px) {
    .studioDescription {
        padding: 5vh 7vw;
    }

    .studioPic {
        width: 100%;
        padding: 2vh 2vw;
    }

    .homePicsContainer {
        display: flex;
        padding: 0 5vw;
    }

    .homeAnnuncio {
        margin-bottom: 5vw;
    }
}