.mapsDescription {
    padding: 0 5vw;
    padding-top: 3vh;
}

@media screen and (min-width: 800px) {
    .mapsDescription {
        padding: 3vh 15vw;
    }
}

.mapsContainer {
    display: flex;
    justify-content: center;
    padding: 4vh 7vw;
}

.mapsLocation {
    max-width: 100%;
    max-height: 100%;
    border: 0;
}