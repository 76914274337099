.navLink {
    color: inherit;
    text-decoration: none;
}

.navigation {
    display: flex;
    flex-direction: column;
    font-size: large;
    margin: 1vh 0;
    background-image: url('../../gallery/theme.jpg');
    background-size: cover;
}

@media screen and (min-width: 800px) {
    .navigation {
        margin: 6vh 0;
    }
}

.navigationRow {
    display: flex;
    justify-content: center;
    column-gap: 12vw;
    padding: 1vh 0;
    border-top: solid 1px rgb(215, 215, 215);
}

.navigationItem {

}