.footer {
    display: flex;
    flex-direction: column;
    align-items: left;
    row-gap: 2vh;
    margin: 2vh 6vw;
    padding: 1vh 1vh;
    margin-bottom: 3vh;
    background-image: url('../../gallery/theme.jpg');
    background-size: cover;
    width: fit-content;
    border: solid 1px rgb(215, 215, 215);
}

@media screen and (min-width: 800px) {
    .footer {
        margin: 5vh 6vw;
        padding: 1vw 1vw;
    }
}

.footerItem, .pIVAitem {
    display: flex;
    align-items: center;
    column-gap: 2vw;
}

.contactValue {
    font-size: large;
}

a {
    color: inherit;
    text-decoration: none;
}

.pIVA {
    display: flex;
    flex-direction: column;
    align-items: right;
    row-gap: 2vh;
    margin: 3vh 6vw;
    padding: 1vh 1vh;
    text-align: left;
}

.pIVAitem {
    margin-left: auto;
}