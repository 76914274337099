.logoContainer {
    position: absolute;
    top: 2vh;
    left: 0;
    width: 24%;
    z-index: -1;
}

@media screen and (min-width: 800px) {
    .logoContainer {
        width: 9%;
    }
}

.logo {
    max-width: 100%;
}