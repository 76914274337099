.pricesContainer {
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    padding: 3vh 6vw;
}

.pricesTitle {
    color: rgb(235, 30, 30);
    font-size: large;
    font-weight: bold;
} 

.listItem {
    padding: 0.5vh 0;
}

.pricesDescription {

}

.extraDescription {
    margin: 2vh 4vw;
    margin-bottom: 6vh;
    padding: 2vw 2vw;
    border: solid 1.5px rgba(88, 171, 116, 0.6);
    border-radius: 4px;
}

@media screen and (min-width: 800px) {
    .extraDescription {
        margin: 2vh 6vw;
        padding: 1vh 1vh;
        margin-bottom: 5vw;
    }
}

.percorsoTitle {
    font-size: large;
    font-weight: bold;
    padding: 0.5vh 0;
}