.aboutContainer {
    display: flex;
    align-items: center;
    column-gap: 4vw;
    padding: 3vh 6vw;
}

@media screen and (max-width: 800px) {

    
}

.aboutTextOnly {
    padding: 0 6vw;
    padding-bottom: 3vh;
}

.profilePic {
    min-width: 40%;
    max-width: 40%;
}

@media screen and (min-width: 800px) {
    .profilePic {
        min-width: 18%;
        max-width: 18%;
    }
}

.profile {
    width: 100%;
}